<template>
  <v-dialog v-model="show_add_object_modal" max-width="500px" persistent>
    <v-card>
      <v-card-title class="subtitle-1">Update {{selected_object.title}}</v-card-title>
      <v-card-text class="py-3">
          <v-row v-show="show_file">
            <v-col 
              :cols="isVideoUpload ? 11 : 12"
              v-if="!isVideoUpload || !showLinkInput"
            >
              <v-file-input
                v-model="form_data.object_file"
                :error-messages="form_error.object_file"
                :accept="(selected_object.type=='VIDEO') ? '.mp4,.webm,.pdf' : 'image/*'"
                placeholder="Select file to upload"
                prepend-icon="mdi-file"
                outlined
                dense
                v-show="show_file"
                v-validate="'size:'+(selected_object.upload_limit*1024)+''"
                name="object_file"
                :hint="`Max file size allowed is ${selected_object.upload_limit}  MB (Only ${(selected_object.type=='VIDEO') ? 'video or pdf' : 'image'} file types allowed)`"
                persistent-hint
              ></v-file-input>
               <span class="red--text">{{ errors.first('object_file') }}</span>
            </v-col>
            <v-col
              cols="11"
              v-if="isVideoUpload && showLinkInput"
            >
              <v-text-field
                v-model="form_data.object_file_link"
                :error-messages="form_error.object_file_link"
                :placeholder="'https://example.com/file.jpeg'"
                dense
                label="File Link"
                prepend-icon="mdi-link"
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="1"
              v-if="isVideoUpload"
              class="pl-0"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-center cursor-pointer" v-bind="attrs" v-on="on" @click="showLinkInput = !showLinkInput">
                    <v-icon class="mt-2">{{
                      showLinkInput ? "mdi-file-upload-outline" : "mdi-link"
                    }}</v-icon>
                  </div>
                </template>
                <span>{{ showLinkInput ? "Switch to File Upload" : "Switch to Link" }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row v-if="selected_object.type=='VIDEO'">
            <v-col cols="12" md="12" sm="12">
              <v-file-input
                v-model="form_data.object_file_thumbnail"
                :error-messages="form_error.object_file_thumbnail"
                accept="image/*"
                placeholder="Select video thumbnail"
                prepend-icon="mdi-file-image"
                outlined
                dense
                class="mb-0"
                v-validate="'size:'+(selected_object.upload_limit*1024)+''"
                :hint="'Max file size allowed is 10 MB (Only image file types are allowed)'"
                persistent-hint
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="11" md="11" sm="11" class="pb-0">
              <v-text-field
                v-model="form_data.link"
                :error-messages="form_error.link"
                dense
                label="Link"
                prepend-icon="mdi-link"
                outlined
                placeholder="https://"
                v-if="!show_contact_details && !custom_file_mode"
                :hint="'This link will open when the '+selected_object.title+ ' is clicked'"
                persistent-hint
              ></v-text-field>
              <v-file-input
                v-if="!show_contact_details && custom_file_mode"
                accept="image/*,video/*,.pdf"
                v-model="form_data.object_custom_file"
                :error-messages="form_error.custom_file"
                placeholder="Select File"
                prepend-icon="mdi-file-upload-outline"
                outlined
                dense
                class="mb-0"
                v-validate="'size:'+(selected_object.upload_limit*1024)+''"
                :hint="'Max file size allowed is 10 MB (Supported png,gif,jpeg,jpg,mp4,pdf files)'"
                persistent-hint
              ></v-file-input>
            </v-col>
            <v-col cols="1" class="pa-0">
              <v-tooltip bottom v-if="!show_contact_details">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="custom_file_mode = !custom_file_mode" v-bind="attrs" v-on="on" left class="mt-3">
                    <v-icon v-if="!custom_file_mode">mdi-file-upload-outline</v-icon>
                    <v-icon v-else>mdi-link</v-icon>
                  </v-btn>
                </template>
                <span>Switch to {{ (!custom_file_mode) ? 'File upload' : 'Link' }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row v-if="show_contact_details">
            <v-col cols="12" md="12" sm="12">
              <v-textarea
                v-model="form_data.contact_details"
                :error-messages="form_error.contact_details"
                dense
                rows="4"
                label="Contact Details"
                :placeholder="`${'Jhon Doe \nxyz@email.com\n+1 111444552 \nhttps://google.com'}`"
                outlined
                autofocus
                no-resize
              ></v-textarea>
            </v-col>
          </v-row>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn
          class="mr-4"
          color="secondary"
          @click="addObject"
          :loading="loading"
          :disabled="loading || errors.first('object_file')!=null"
          >SAVE</v-btn
        >
        <v-btn class="mr-4" text @click="closeObjectModal();form_error = {}">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "AddVendor",
  props: {
    position: String,
  },
  data() {
    return {
      positions: [],
      form_data: {
        vendor_id: null
      },
      form_error: {},
      loading: false,
      data: {},
      show_file: false,
      show_contact_details: false,
      showLinkInput: false,
      custom_file_mode : false
    };
  },
  watch: {
    show_add_object_modal(value) {
      if (value) {
        this.getPositionList();
        // this.form_data.contact_details = this.selected_object.contact_details
        this.custom_file_mode = false
        this.form_data.selected_object = this.selected_object.type
      } else {
        this.closeObjectModal().then(()=>{
          this.form_data = {};
          this.showLinkInput = false;
        });
      }
    },
  },
  computed: {
    ...mapState("add_object", ["show_add_object_modal","selected_object"]),
    isVideoUpload: function() {
      return this.selected_object.type == 'VIDEO';
    }
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    ...mapMutations("add_object", ["setObjectData"]),
    ...mapActions("add_object", ["closeObjectModal"]),
    ...mapActions("vendor_booth", ["getBoothsDetails"]),
    addObject() {
      let _self = this;
      this.loading = "secondary";
      let serverData = JSON.parse(JSON.stringify(this._data.form_data));
      let formData = new FormData();
      for (let key in serverData) {
        formData.append(key, serverData[key]);
      }
      if (this.form_data.object_file) {
        formData.append("object_file", this.form_data.object_file);
      }
      if (this.form_data.object_file_thumbnail) {
        formData.append("object_file_thumbnail", this.form_data.object_file_thumbnail);
      }

      if (this.isVideoUpload) {
        formData.append("is_file_link", this.showLinkInput);
        formData.set("object_file_link", this.showLinkInput ? this.form_data.object_file_link || "" : "")
      } else {
        formData.delete("is_file_link");
        formData.delete("object_file_link");
      }

      if(this.custom_file_mode){
        formData.append("custom_file", this.form_data.object_custom_file);
      }
      formData.append("is_social", this.show_file);
      formData.append("is_contact_details", this.show_contact_details);
      this.$axios.post(`vendor/object/add`, formData)
        .then((response) => {
          _self.form_error = {};
          let response_data = response.data;
          if (response_data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
            _self.form_data = {};
            _self.showLinkInput = false;
           _self.getBoothsDetails();
           _self.closeObjectModal().then(()=>{
             _self.form_data = {};
             _self.showLinkInput = false;
           });
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    getPositionList() {
      let _self = this;
      let p_id;
      _self.loading = true;
      this.$axios
        .get(`vendor/position_list`)
        .then((response) => {
          let response_data = response.data;
          if (response_data.status) {
            _self.positions = response_data.data;
            p_id = _self.positions.find((position) => {
              return position.type === _self.selected_object.type;
            });
            _self.form_data.position_id = p_id.vo_position_id;
            this.getType(_self.form_data.position_id);
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
      //
    },
    getType(position_id) {
      var result = this.positions.find((obj) => {
        return obj.vo_position_id === position_id;
      });
      console.log("type " + result.type);
      this.show_file = result.type == "CONTACT" ? false : true;
      this.show_contact_details = result.type == "CONTACT" ? true : false;
    }
  },
  mounted: function () {},
};
</script>

<style scoped>
  .cursor-pointer {
    cursor: pointer;
  }
</style>