import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);
let opts = {
  theme: {
    themes: {
      light: {
        primary: "#114c8f",
        secondary: "#792d8d",
        error: "#EF5350",
      },
      dark: {
        secondary: "#1a4476",
        primary: "#80bc40",
        error: "#EF5350",
      },
    },
  },
};
export default new Vuetify(opts);
