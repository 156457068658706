<template>
   <v-snackbar v-model="alert.show" bottom :color="alert.variant" :timeout="alert.dismissCountDown">
      {{alert.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          v-bind="attrs"
          @click="closeAlert"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>
import {mapState,mapMutations} from 'vuex'
export default {
  name : 'Alert',
  data(){
    return {
      snackbar : true
    }
  },
  methods : {
    ...mapMutations('utils',['setAlert']),
    closeAlert(){
      this.setAlert({
        show : false,
        variant : '',
        message : '',
        dismissCountDown : 0,
      })
    }
  },
  computed : {
    ...mapState('utils',['alert'])
  }
}
</script>

<style>

</style>