<template>
  <span>
    <v-speed-dial bottom fixed class="mb-3 mr-4 bg-white" right>
      <template v-slot:activator>
        <v-row dark>
          <v-btn color="primary" to="/booth" tile>
            <v-icon left> mdi-arrow-left </v-icon>
            Back
          </v-btn>
        </v-row>
      </template>
    </v-speed-dial>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1920 1440"
      style="enable-background: new 0 0 1920 1440"
      xml:space="preserve"
    >
      <g id="Color_Selections_1_">
        <g id="White_xA0_Image_1_">
          <image
            style="overflow: visible"
            width="1920"
            height="1440"
            id="White_xA0_Image"
            :xlink:href="vendor_details.booth_image"
          ></image>
        </g>
      </g>
      <g id="Social_Media_Links_1_">
        <g
          :id="'Rectangle_9_' + items.v_social_media_id"
          v-for="(items, index) in social_links"
          :key="items.v_social_media_id"
        >
          <g>
            <rect
              :x="index == 0 ? xb : xb + index * 45"
              y="1035"
              class="st0"
              width="32"
              height="32"
            />
          </g>
        </g>
        <g
          :id="'Layer_10_xA0_Image_1_' + items.v_social_media_id"
          v-for="(items, index) in social_links"
          :key="'sub_' + items.v_social_media_id"
        >
          <a href="javascript:;" @click="openLink(items.link)">
            <image
              style="overflow: visible"
              width="32"
              height="32"
              :id="`Layer_10_xA0_${items.v_social_media_id}_Image`"
              :x="index == 0 ? xb : xb + index * 45"
              y="1035"
              :xlink:href="`${asset}${items.name}_icon.png`"
            ></image>
          </a>
        </g>
      </g>
      <g id="Rectangle_7_1_" class="st1">
        <g id="Rectangle_7">
          <g>
            <rect
              x="410"
              y="1010"
              class="st0_transparent"
              width="280"
              height="90"
              ref="contact_image"
            />
          </g>
        </g>
      </g>
      <g id="Rectangle_5_1_" class="st1">
        <g id="Rectangle_5">
          <g>
            <rect
              x="1402"
              y="675"
              class="st0_transparent"
              width="180"
              height="180"
            />
          </g>
        </g>
      </g>
      <g id="Layer_20_xA0_Image_1_" @click="openLink(vendor_objects.B3.link)">
        <a href="javascript:;">
          <image
            style="overflow: visible"
            width="180"
            height="180"
            id="Layer_20_xA0_Image"
            :xlink:href="vendor_objects.B3.upload_url"
            transform="matrix(1 0 0 1 1402 675)"
          ></image>
        </a>
      </g>
      <g id="Rectangle_4_1_" class="st1">
        <g id="Rectangle_4">
          <g>
            <rect x="914" y="675" class="st0" width="426" height="240" />
          </g>
        </g>
      </g>
      <g id="Layer_19_xA0_Image_1_">
        <a
          href="javascript:;"
          @click="
            ShowVideo(vendor_objects.B1.upload_url, vendor_objects.B1.type)
          "
        >
          <image
            style="overflow: visible"
            width="426"
            height="240"
            id="Layer_19_xA0_Image"
            :xlink:href="vendor_objects.B1.link"
            transform="matrix(1 0 0 1 914 675)"
          ></image>
        </a>
      </g>
      <g id="Rectangle_3_1_" class="st1">
        <g id="Rectangle_3" ref="desc_image">
          <g>
            <rect x="653" y="675" class="st0" width="200" height="240" />
          </g>
        </g>
      </g>
      <g id="Layer_18_xA0_Image_1_">
        <span></span>
      </g>
      <g id="Rectangle_2_1_" class="st1">
        <g id="Rectangle_2">
          <g>
            <rect x="739" y="515" class="st0" width="240" height="48" />
          </g>
        </g>
      </g>
      <g id="Layer_17_xA0_Image_1_">
        <a href="javascript:;" @click="openResources(vendor_details.vendor_id)">
          <image
            style="overflow: visible"
            width="240"
            height="48"
            id="Layer_17_xA0_Image"
            :xlink:href="asset + 'res_button.gif'"
            transform="matrix(1 0 0 1 739 515)"
          ></image>
        </a>
      </g>
      <g id="Rectangle_1_1_" class="st1">
        <g id="Rectangle_1">
          <g>
            <rect
              x="379"
              y="512"
              class="st0_transparent"
              width="334"
              height="132"
            />
          </g>
        </g>
      </g>
      <g id="Layer_16_xA0_Image_1_">
        <a href="javascript:;" @click="openLink(vendor_objects.B2.link)">
          <image
            style="overflow: visible"
            width="334"
            height="132"
            id="Layer_16_xA0_Image"
            :xlink:href="vendor_objects.B2.upload_url"
            transform="matrix(1 0 0 1 379 512)"
          ></image>
        </a>
      </g>
    </svg>
    <span
      ref="custom_html"
      style="color: white; font-size: 13px; /* text-align: justify; */"
      class="pl-1"
      v-if="vendor_details.comapny_desc"
    >
      <span
        class="ellipsis white_nested"
        ref="custom_sub_html"
        v-html="vendor_details.comapny_desc"
      ></span>
      <span
        v-if="vendor_details.comapny_desc.length > desc_size"
        class="yellow--text cursor_pointer"
        @click="show_desc_modal = true"
      >
        Read More
      </span>
    </span>
    <span
      v-else
      ref="custom_html"
      style="color: white; font-size: 13px"
      class="pl-1"
    ></span>
    <!---COntact Details -->
    <span
      ref="custom_contact_html"
      style="
        color: white;
        font-size: 1vw;
        white-space: break-spaces;
        line-height: 1.1vw;
      "
      class="pl-1 ellipsis"
      v-if="vendor_details.object_data.B4"
      v-html="vendor_objects.B4.contact_details"
    >
    </span>
    <span
      v-else
      ref="custom_contact_html"
      style="color: white; font-size: 13px"
      class="pl-1"
    >
    </span>
    <!---COntact Details -->
    <AddObject v-bind:position="selected_position" />
    <v-dialog v-model="show_preview" max-width="50%">
      <v-card>
        <!-- <video :src="preview_data" width="100%" controls></video> -->
        <video
          :src="preview_data"
          width="100%"
          autoplay
          v-if="isVideo(preview_data)"
          controls
        ></video>
        <iframe
          frameborder="0"
          :src="preview_data"
          v-else
          style="min-height: 500px; min-width: 100%"
        ></iframe>
        <!-- <video :src="preview_data" width="100%" autoplay v-if="preview_data" controls></video> -->
        <v-btn
          style="position: absolute; top: 10px; right: 10px"
          color="red"
          dark
          fab
          small
          @click="
            show_preview = false;
            preview_data = null;
          "
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
    <v-dialog v-model="show_desc_modal" max-width="50%">
      <v-card>
        <v-card-title class="pa-3 pb-1">Company Description</v-card-title>
        <v-card-text
          class="pa-3 pb-0 rounded-lg"
          v-html="vendor_details.comapny_desc"
          style="white-space: pre-wrap"
        ></v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" text @click="show_desc_modal = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ResourcePopup />
  </span>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import AddObject from "@/components/booth/AddObject";
import ResourcePopup from "@/components/resources/ResourcePopup.vue";
export default {
  name: "MainImage",
  props: {
    drawer: Boolean,
  },
  components: {
    AddObject,
    ResourcePopup,
  },
  data() {
    return {
      selected_booth_color: "grey",
      loading: false,
      preview_data: null,
      show_preview: false,
      show_desc_modal: false,
      selected_position: null,
      respresentative: [],
      desc_y: 0,
      xb: 693,
      last_xb: 693,
      objects: {        G1: { upload_url: "" },
        G2: { upload_url: "" },
        G3: { upload_url: process.env.VUE_APP_ASSETS + "giveaway_image.png" },
        G4: { upload_url: "", contact_details: "" },
        G5: { upload_url: "" },
        G6: { upload_url: "" },
      },
      booth_colors: [
        { name: "Red", color: "red" },
        { name: "Red-Orange", color: "red_orange" },
        { name: "Orange", color: "orange" },
        { name: "Yellow", color: "yellow" },
        { name: "Yellow-Orange", color: "yellow_orange" },
        { name: "Yellow-Green", color: "yellow_green" },
        { name: "Green", color: "green" },
        { name: "Blue-Green", color: "blue_green" },
        { name: "Blue", color: "blue" },
        { name: "Blue-Violet", color: "blue_violet" },
        { name: "Violet", color: "violet" },
        { name: "Red-Violet", color: "red_violet" },
      ],
      booth_image: null,
      desc_size: 180,
    };
  },
  watch: {
    "vendor_details.booth_color": function (value) {
      if (value) {
        this.selected_booth_color = this.vendor_details.booth_color;
      }
    },
    show_preview: function (value) {
      if (!value) {
        this.preview_data = null;
      }
    },
  },
  computed: {
    ...mapState("utils", ["maxWidth", "isBoothPreview"]),
    ...mapState("vendor_booth", ["vendor_details", "vendor_objects"]),
    ...mapState("resource", ["resource_vendor_id", "resource_start_from"]),
    asset() {
      return process.env.VUE_APP_ASSETS;
    },
    intro_video_thumb() {
      return process.env.VUE_APP_ASSETS + "intro_video_thumb.png";
    },
    social_links() {
      return this.vendor_details.social_links.filter((item) => {
        return item.link;
      });
    },
  },
  methods: {
    ...mapMutations("utils", ["setUtilData"]),
    ...mapMutations("add_object", ["setObjectData"]),
    ...mapMutations("vendor_booth", ["setBoothData"]),
    ...mapActions("vendor_booth", ["getBoothsDetails"]),
    ...mapMutations("resource", ["setResourceData"]),
    openLink(href) {
      if (href) {
        Object.assign(document.createElement("a"), {
          target: "_blank",
          href,
        }).click();
      }
    },
    isShowReadMore(description) {
      return Math.round(description.length / 20) > 12;
    },
    openBoothDetails(vendor_id) {
      this.$router.push("/vendor/" + vendor_id);
    },
    ShowVideo(video_src, type) {
      if (type == "VIDEO") {
        this.show_preview = true;
        this.preview_data = video_src;
      }
    },
    openResources(vendor_id) {
      this.setResourceData({
        key: "show_resource_modal",
        data: true,
      });
      this.setResourceData({
        key: "resource_vendor_id",
        data: vendor_id,
      });
    },
    getCoords(elem) {
      // crossbrowser version
      var box = elem.getBoundingClientRect();
      console.log(box);
      var body = document.getElementsByTagName("svg")[0];
      var docEl = body;

      var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
      var scrollLeft =
        window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

      var clientTop = docEl.clientTop || body.clientTop || 0;
      var clientLeft = docEl.clientLeft || body.clientLeft || 0;

      var top = box.top + scrollTop - clientTop;
      var left = box.left + scrollLeft - clientLeft;
      var width = box.width;
      var height = box.height;

      return {
        top: Math.round(top),
        left: Math.round(left),
        width: Math.round(width),
        height: Math.round(height),
      };
    },
    setWidthOfCustomHtml() {
      let desc_image = this.$refs["desc_image"];
      let custom_html = this.$refs["custom_html"];
      let custom_sub_html = this.$refs["custom_sub_html"];
      let bounds = this.getCoords(desc_image);
      custom_html.style.position = "absolute";
      custom_html.style.left = bounds.left + "px";
      custom_html.style.top = bounds.top - 45 + "px";
      custom_html.style.width = bounds.width + "px";
      custom_html.style.height = bounds.height - 15 + "px";
      if (custom_sub_html) {
        custom_sub_html.style.height = bounds.height - 20 + "px";
      }
      this.desc_size = bounds.height;
    },
    setWidthOfCustomContact() {
      let desc_image = this.$refs["contact_image"];
      let custom_html = this.$refs["custom_contact_html"];
      let bounds = this.getCoords(desc_image);
      custom_html.style.position = "absolute";
      custom_html.style.left = bounds.left + "px";
      custom_html.style.top = bounds.top - 50 + "px";
      custom_html.style.width = bounds.width + "px";
      custom_html.style.height = bounds.height + "px";
      this.desc_size = bounds.height;
    },
    isVideo(link) {
      if (this.preview_data) {
        let url = link.split(".");
        url = url[url.length - 1];
        let suportedVideos = ["mp4", "webm"];
        if (suportedVideos.includes(url)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.loading = true;
    this.getBoothsDetails(true).then(() => {
      this.setWidthOfCustomHtml();
      this.setWidthOfCustomContact();
    });
    window.onresize = () => {
      this.setWidthOfCustomHtml();
      this.setWidthOfCustomContact();
    };
  },
};
</script>
<style type="text/css">
.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #00000069 !important;
}
.st0_transparent {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #0db04b00;
}
.st0_contact {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #727272;
}
.st1 {
  enable-background: new;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
span.ellipsis > p {
  margin-bottom: 0px;
  color: white;
}
span.ellipsis > p > * {
  color: white !important;
}
.cursor_pointer {
  cursor: pointer;
}
</style>