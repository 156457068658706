import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import store from './store'
import moment from 'moment'
import DatetimePicker from 'vuetify-datetime-picker'
import axios from './helper/axios'
import VueClipboard from 'vue-clipboard2'
import Vue2Editor from "vue2-editor";
import VeeValidate from 'vee-validate';
Vue.prototype.FRONT_ASSETS = process.env.VUE_APP_ASSETS
Vue.prototype.moment = moment
Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.use(DatetimePicker)
Vue.use(VueClipboard)
Vue.use(Vue2Editor);
Vue.use(VeeValidate);

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
