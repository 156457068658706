import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../components/auth/Login.vue";
import Forgotpassword from "../components/auth/Forgotpassword.vue";
import Resetpassword from "../components/auth/Resetpassword.vue";
import MainLayout from "../components/layout/Layout.vue";
import Dashboard from "../components/Dashboard.vue";
import Profile from "../components/Profile.vue";
import ChangePassword from "../components/ChangePassword.vue";
import RepesentativeList from "../components/representative/List.vue";
import BoothManagement from "../components/booth/VendorBooth.vue";
import BoothManagementPreview from "../components/booth/VendorBoothPreview.vue";
import ResourceList from "../components/resources/List.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "PCM | Login",
    },
  },
  {
    path: "/forgot-password",
    name: "Forgotpassword",
    component: Forgotpassword,
    meta: {
      title: "PCM | Forgot password",
    },
  },
  {
    path: "/reset-password/:id/:token",
    name: "Resetpassword",
    component: Resetpassword,
    meta: {
      title: "PCM | Reset password",
    },
  },
  {
    path: "/",
    component: MainLayout,
    children: [
      {
        path: "",
        redirect: "/booth",
      },
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          title: "PCM | Dashboard",
          boothview: false,
        },
      },
      {
        path: "profile",
        name: "Profile",
        component: Profile,
        meta: {
          title: "PCM | Profile",
          boothview: false,
        },
      },
      {
        path: "change-password",
        name: "ChangePassword",
        component: ChangePassword,
        meta: {
          title: "PCM | Change Password",
          boothview: false,
        },
      },
      {
        path: "booth",
        name: "BoothManagement",
        component: BoothManagement,
        meta: {
          title: "PCM | Booth Management",
          boothview: true,
        },
      },
      {
        path: "booth/preview",
        name: "BoothManagementPreview",
        component: BoothManagementPreview,
        meta: {
          title: "PCM | Booth Management",
          boothview: false,
        },
      },
      {
        path: "repesentative",
        name: "Repesentative",
        component: RepesentativeList,
        meta: {
          title: "PCM | Repesentative",
          boothview: false,
        },
      },
      {
        path: "resource/list",
        name: "Resources",
        component: ResourceList,
        meta: {
          title: "PCM | Resources",
          boothview: false,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  const publicPages = [
    "/login",
    "/forgot-password",
    "/reset-password/" + to.params.id + "/" + to.params.token,
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = JSON.parse(localStorage.getItem("vendor_data"));
  document.title = to.meta.title;
  if (authRequired && !loggedIn) {
    next("/login");
  } else if (!authRequired && loggedIn) {
    if (to.name === "Login" && to.query?.code) {
      localStorage.removeItem("vendor_data");
      next();
    } else {
      next("/booth");
    }
  } else {
    next();
  }
});
export default router;
