<template>
  <v-container>
    <v-card :loading="loading" class="elevation-2 mx-auto" max-width="600">
      <v-card-title>Change Password</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <form>
          <v-text-field
            type="password"
            v-model="current_password"
            :error-messages="form_error.current_password"
            label="Current Password"
            outlined
            dense
          ></v-text-field>
          <v-text-field
            type="password"
            v-model="new_password"
            :error-messages="form_error.new_password"
            label="New Password"
            outlined
            dense
          ></v-text-field>
          <v-text-field
            type="password"
            v-model="confirm_password"
            :error-messages="form_error.confirm_password"
            label="Confirm Password"
            outlined
            dense
          ></v-text-field>
          <v-btn
            class="mr-4"
            color="secondary"
            @click="updateProfile"
            :loading="loading"
            :disabled="loading"
          >submit</v-btn>
        </form>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  name: "Login",
  data() {
    return {
      form_error: {},
      current_password: null,
      new_password: null,
      confirm_password: null,
      loading: false,
      data: {},
    };
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    updateProfile() {
      let _self = this;
      this.loading = "secondary";
      this.$axios.post(`change_password`, {
        current_password: this.current_password,
        new_password: this.new_password,
        confirm_password: this.confirm_password,
      })
        .then((response) => {
          _self.form_error = {};
          let response_data = response.data;
          if (response_data.status) {
            this.data = response_data.user_data;
            localStorage.setItem(
              "vendor_data",
              JSON.stringify(response_data.user_data)
            );
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
            this.user_data = response_data.user_data;
            // window.location.href = '/dashboard'
            // _self.$router.push('/dashboard')
            this.current_password = null
            this.new_password = null
            this.confirm_password = null
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              console.log(errors[key]);
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
  },
  mounted: function () {
    this.user_data = JSON.parse(localStorage.getItem("vendor_data"));
    this.name = this.user_data.company_name;
    this.email = this.user_data.email;
  },
};
</script>