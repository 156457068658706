<template>
  <v-dialog
    v-model="show_resource_modal"
    max-width="1200"
    @click:outside="closePopup"
  >
    <v-card>
      <v-toolbar dark flat color="primary" dense>
        <v-toolbar-title>Resources</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closePopup">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-row no-gutters>
        <v-col cols="12">
          <v-card flat>
            <v-card-text>
              <v-list>
                <div v-for="(item, index) in resource_list.data" :key="index">
                  <v-list-item>
                    <v-btn color="primary" class="mx-1" :href="item.file" target="_blank">
                      view
                    </v-btn>
                    <v-list-item-content class="mx-4">
                      <v-list-item-title>{{item.title}}</v-list-item-title>
                      <v-list-item-subtitle>{{item.description}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider v-if="index != resource_list.length - 1"></v-divider>
                </div>
                <div v-if="resource_list.length == 0">
                  No Resources Found!
                </div>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState,mapActions,mapMutations } from 'vuex'
export default {
  name: "ResourcePopup",
  data() {
    return {
      selected : []
    };
  },
  watch: {
    show_resource_modal(value) {
      if (value) {
        this.fetchResources();
      }
    },
  },
  computed: {
    ...mapState("resource", ["show_resource_modal", "resource_list"]),
  },
  methods: {
    ...mapActions("resource", ["fetchResources"]),
    ...mapMutations("resource", ["setResourceData"]),
    ...mapMutations("utils", ["setAlert"]),
    closePopup() {
      this.setResourceData({
        key: "show_resource_modal",
        data: false,
      });
      this.setResourceData({
        key: "resource_list",
        data: {},
      });
      this.setResourceData({
        key: "total_resource",
        data: 0,
      });
      this.setResourceData({
        key: "resource_start_from",
        data: 0,
      });
    }
  },
  mounted: function () {},
};
</script>